import React, { useState } from "react";
import WipeBtnBlack from "../../../components/atoms/buttons/WipeBtnBlack";
import Fade from "react-reveal/Fade";
// import careerImage from "../../../images/homepageassets/career.png";
// import lifeAtGokenImage from "../../../images/homepageassets/lifeatgoken.png";
// import { Link } from "gatsby";

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";

import { useLanguage } from "../../../languageContext";

import sliderCardIcon from "../../../images/servicesassets/slider-card-icon.png";

import leftArren from "../../../images/servicesassets/leftArrow-en.png";
import leftArrin from "../../../images/servicesassets/leftArrow-in.png";
import leftArrja from "../../../images/servicesassets/left-Arrow-ja.png";

import rightArren from "../../../images/servicesassets/rightArrow-en.png";
import rightArrin from "../../../images/servicesassets/rightArrow-in.png";
import rightArrja from "../../../images/servicesassets/right-Arrow-ja.png";

const HomepageCareersk = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const currentLanguage = useLanguage();
  const testimonialSlides = [
    {
      paragraph:
        "“At Goken, we have people from all walks of life. Whenever we come across a challenge, our associates come together to form a high performing team and take the challenge head on. Not because they have to, but because they want to.”",
      designation: "Zeyn Kermani​",
      companyName: "Manager Engineer Services​​",
      index: 0,
    },
    {
      paragraph:
        "“The best thing about Goken is “it has ears”!  The management has an open-door policy and will do everything they can to help you achieve the career path you envision.”",
      designation: "​Aruna Kumara​",
      companyName: "Design Engineer III​​",
      index: 1,
    },
    {
      paragraph:
        "“The organization is very entrusting of all their associates. Their actual self-managed project-based model has really helped me make my own schedule and allowed me to grow interpersonally.”",
      designation: "​Brandon Bordner​",
      companyName: "Technical Specialist​​",
      index: 2,
    },
    {
      paragraph:
        '"I got into engineering to help people. The fact that the Goken mission statement focuses on the importance of not only the client but also the associates, appealed to me. The idea of being better together with the goal of helping others is something that resonates with me." ',
      designation: "Administrative Assistant​",
      companyName: "Goken Associate​",
      index: 3,
    },
    {
      paragraph:
        '"What attracted me to Goken was the culture that fosters innovation and knowledge retention and the fact that good people are trained to be great engineers. I am extremely honored to be a part of Goken America."',
      designation: "Design Engineer​",
      companyName: "Goken Associate​",
      index: 4,
    },
  ];

  const hoverHandler = (event) => {
    console.log(event, "event");
    // const element = document.querySelectorAll(".testimonial-item");
    // element.forEach((card) => {
    //   card.classList.add("testimonial-opacity");
    // });
    // console.log(event.target, "eventtzargdw");
    // const catchEventDiv = event.target;
    // console.log(catchEventDiv, "catch event div");

    // const catchEvenList = event.target.classList.value;
    // console.log(catchEvenList, "catch event list");
    // console.log(catchEventDiv.classList, "classlist");

    // event.target.classList.remove("testimonial-opacity");
  };

  const removeHoverHandler = (event) => {
    console.log(event, "remove hover handler");
    const element = document.querySelectorAll(".testimonial-item");
    element.forEach((card) => {
      card.classList.remove("testimonial-opacity");
    });
  };

  return (
    <div className="section">
      <div className="container">
        <div className="careers-wrapper">
          <div className="container">
            <div className="aboutus-testimonial-section">
              <Fade bottom cascade>
                <div className="heading heading-bottom-space">CAREERS</div>
              </Fade>
              <div className="para common-bottom-space">
                This is what our associates have to say about working at Goken
              </div>
              <br></br>

              <div
                className="slider-wrapper"
                onMouseEnter={(event) => hoverHandler(event)}
                onMouseLeave={(event) => removeHoverHandler(event)}
              >
                <CarouselProvider
                  naturalSlideWidth={100}
                  naturalSlideHeight={135}
                  totalSlides={testimonialSlides.length}
                  visibleSlides={3}
                  isPlaying={isPlaying}
                  infinite={true}
                  onMouseEnter={() => setIsPlaying(false)}
                  onMouseLeave={() => setIsPlaying(true)}
                  interval={2000}
                >
                  <ButtonBack className="slider-btn">
                    <img
                      src={
                        currentLanguage === "en"
                          ? leftArren
                          : currentLanguage === "in"
                          ? leftArrin
                          : currentLanguage === "ja"
                          ? leftArrja
                          : leftArren
                      }
                      alt="Slide"
                    ></img>
                  </ButtonBack>
                  <ButtonNext className="slider-btn">
                    <img
                      src={
                        currentLanguage === "en"
                          ? rightArren
                          : currentLanguage === "in"
                          ? rightArrin
                          : currentLanguage === "ja"
                          ? rightArrja
                          : rightArren
                      }
                      alt="Slide"
                    ></img>
                  </ButtonNext>
                  <Slider>
                    {testimonialSlides &&
                      testimonialSlides.map(
                        ({
                          paragraph,
                          designation,
                          companyName,
                          index,
                          logoImage,
                        }) => (
                          <Slide index={index}>
                            {" "}
                            <div
                              className="testimonial-item  box-shadow"
                              // onMouseEnter={(event) => hoverHandler(event)}
                              // onMouseLeave={(event) =>
                              //   removeHoverHandler(event)
                              // }
                            >
                              {logoImage ? (
                                <img
                                  src={logoImage.url}
                                  alt="Company Logo Image"
                                ></img>
                              ) : (
                                <img
                                  className="slider-card-icon"
                                  alt="Company Logo Image"
                                  src={sliderCardIcon}
                                ></img>
                                // <div className={`testimonial-icon bg-en`}></div>
                              )}
                              <div className="para">{paragraph}</div> <br></br>
                              <div className="name-wrapper card-text">
                                {designation}
                                <br></br>
                                <span>{companyName}</span>
                              </div>
                            </div>
                          </Slide>
                        )
                      )}
                  </Slider>
                </CarouselProvider>
              </div>
              <div className="testimonial-mobile-wrapper">
                <CarouselProvider
                  naturalSlideWidth={8}
                  naturalSlideHeight={10}
                  totalSlides={testimonialSlides.length}
                  visibleSlides={1}
                  isPlaying={isPlaying}
                  infinite={true}
                  onMouseEnter={() => setIsPlaying(false)}
                  onMouseLeave={() => setIsPlaying(true)}
                  interval={2000}
                >
                  <ButtonBack className="slider-btn">
                    <img
                      src={
                        currentLanguage === "en"
                          ? leftArren
                          : currentLanguage === "in"
                          ? leftArrin
                          : currentLanguage === "ja"
                          ? leftArrja
                          : leftArren
                      }
                      alt="Slide"
                    ></img>
                  </ButtonBack>
                  <ButtonNext className="slider-btn">
                    <img
                      src={
                        currentLanguage === "en"
                          ? rightArren
                          : currentLanguage === "in"
                          ? rightArrin
                          : currentLanguage === "ja"
                          ? rightArrja
                          : rightArren
                      }
                      alt="Slide"
                    ></img>
                  </ButtonNext>
                  <Slider>
                    {testimonialSlides &&
                      testimonialSlides.map(
                        ({
                          companyName,
                          designation,
                          logoImage,
                          paragraph,
                          index,
                        }) => (
                          <Slide index={index}>
                            {" "}
                            <div className="testimonial-item box-shadow">
                              {logoImage ? (
                                <img
                                  src={logoImage.url}
                                  alt="Company Logo Image"
                                ></img>
                              ) : (
                                <div className={`testimonial-icon bg-en`}></div>
                              )}
                              <div className="para">{paragraph}</div> <br></br>
                              <div className="name-wrapper">
                                {designation}
                                <br></br>
                                <span>{companyName}</span>
                              </div>
                            </div>
                          </Slide>
                        )
                      )}
                  </Slider>
                </CarouselProvider>
              </div>
              <div className="testimonial-tablet-wrapper">
                <CarouselProvider
                  naturalSlideWidth={100}
                  naturalSlideHeight={130}
                  totalSlides={testimonialSlides.length}
                  visibleSlides={2}
                  isPlaying={isPlaying}
                  infinite={true}
                  onMouseEnter={() => setIsPlaying(false)}
                  onMouseLeave={() => setIsPlaying(true)}
                  interval={2000}
                >
                  <ButtonBack className="slider-btn">
                    <img
                      src={
                        currentLanguage === "en"
                          ? leftArren
                          : currentLanguage === "in"
                          ? leftArrin
                          : currentLanguage === "ja"
                          ? leftArrja
                          : leftArren
                      }
                      alt="Slide"
                    ></img>
                  </ButtonBack>
                  <ButtonNext className="slider-btn">
                    <img
                      src={
                        currentLanguage === "en"
                          ? rightArren
                          : currentLanguage === "in"
                          ? rightArrin
                          : currentLanguage === "ja"
                          ? rightArrja
                          : rightArren
                      }
                      alt="Slide"
                    ></img>
                  </ButtonNext>
                  <Slider>
                    {testimonialSlides &&
                      testimonialSlides.map(
                        ({
                          companyName,
                          designation,
                          logoImage,
                          paragraph,
                          selectCountry,
                          index,
                        }) => (
                          <Slide index={index}>
                            {" "}
                            <div
                              className="testimonial-item box-shadow"
                              onMouseEnter={(event) => hoverHandler(event)}
                              onMouseLeave={(event) =>
                                removeHoverHandler(event)
                              }
                            >
                              {logoImage ? (
                                <img
                                  src={logoImage.url}
                                  alt="Company Logo Image"
                                ></img>
                              ) : (
                                <div className={`testimonial-icon bg-en`}></div>
                              )}
                              <div className="para">{paragraph}</div> <br></br>
                              <div className="name-wrapper">
                                {designation}
                                <br></br>
                                <span>{companyName}</span>
                              </div>
                            </div>
                          </Slide>
                        )
                      )}
                  </Slider>
                </CarouselProvider>
              </div>
              <div
                className="testimonial-xl-wrapper"
                onMouseEnter={(event) => hoverHandler(event)}
                onMouseLeave={(event) => removeHoverHandler(event)}
              >
                <CarouselProvider
                  naturalSlideWidth={100}
                  naturalSlideHeight={95}
                  totalSlides={testimonialSlides.length}
                  visibleSlides={3}
                  // isPlaying={isPlaying}

                  // infinite={true}
                  // interval={2000}
                  // onMouseEnter={() => setIsPlaying(false)}
                  // onMouseLeave={() => setIsPlaying(true)}
                >
                  <ButtonBack className="slider-btn">
                    <img
                      src={
                        currentLanguage === "en"
                          ? leftArren
                          : currentLanguage === "in"
                          ? leftArrin
                          : currentLanguage === "ja"
                          ? leftArrja
                          : leftArren
                      }
                      alt="Slide"
                    ></img>
                  </ButtonBack>
                  <ButtonNext className="slider-btn">
                    <img
                      src={
                        currentLanguage === "en"
                          ? rightArren
                          : currentLanguage === "in"
                          ? rightArrin
                          : currentLanguage === "ja"
                          ? rightArrja
                          : rightArren
                      }
                      alt="Slide"
                    ></img>
                  </ButtonNext>

                  <Slider>
                    {testimonialSlides &&
                      testimonialSlides.map(
                        ({
                          companyName,
                          designation,
                          logoImage,
                          paragraph,
                          selectCountry,
                          index,
                        }) => (
                          <Slide index={index}>
                            {" "}
                            <div className="testimonial-item box-shadow">
                              {logoImage ? (
                                <img
                                  src={logoImage.url}
                                  alt="Company Logo Image"
                                ></img>
                              ) : (
                                <div className={`testimonial-icon bg-en`}></div>
                              )}
                              <div className="para">{paragraph}</div> <br></br>
                              <div className="name-wrapper">
                                {designation}
                                <br></br>
                                <span>{companyName}</span>
                              </div>
                            </div>
                          </Slide>
                        )
                      )}
                  </Slider>
                </CarouselProvider>
              </div>
            </div>
          </div>
          <div className="careers-cta">
            <div className="heading">READY TO GET STARTED ?</div>
            <div className=" careers-buttons m-top-20px">
              <WipeBtnBlack
                text="Explore Career Opportunities"
                url="/careers/"
                // notBtn={true}
              ></WipeBtnBlack>
              {/* <WipeBtnBlack
                text="Explore Life at Goken"
                url="/experiencelifeatgoken"
                // notBtn={true}
              ></WipeBtnBlack> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageCareersk;
