import React, { useState } from "react";
import WipeBtnBlack from "../../../components/atoms/buttons/WipeBtnBlack";
// import Fade from "react-reveal/Fade";
// import careerImage from "../../../images/homepageassets/career.png";
// import lifeAtGokenImage from "../../../images/homepageassets/lifeatgoken.png";
// import { Link } from "gatsby";

// import {
//   CarouselProvider,
//   Slider,
//   Slide,
//   ButtonBack,
//   ButtonNext,
// } from "pure-react-carousel";
// import "pure-react-carousel/dist/react-carousel.es.css";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { useLanguage } from "../../../languageContext";

import sliderCardIcon from "../../../images/servicesassets/slider-card-icon.png";

import leftArren from "../../../images/servicesassets/leftArrow-en.png";
import leftArrin from "../../../images/servicesassets/leftArrow-in.png";
import leftArrja from "../../../images/servicesassets/leftArrow-ja.png";

import rightArren from "../../../images/servicesassets/rightArrow-en.png";
import rightArrin from "../../../images/servicesassets/rightArrow-in.png";
import rightArrja from "../../../images/servicesassets/rightArrow-ja.png";

const SliderCarousel = ({ hideTitle = false }) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const currentLanguage = useLanguage();
  const testimonialSlides = [
    {
      paragraph:
        "“Working for Goken at an Automotive OEM is something I take great pride in. When my peers hear “Goken” it carries automatic respect and a different spark than other company names.”",
      designation: "Rashid Khan",
      companyName: "Test Engineer",
    },
    {
      paragraph: "“Best Employer in Central Ohio for Mechanical Engineers”",
      designation: "GlassDoor.com ",
      companyName: "Associate Review",
    },
    {
      paragraph:
        "“At Goken, we have people from all walks of life. Whenever we come across a challenge, our associates come together to form a high performing team and take the challenge head on. Not because they have to, but because they want to.”",
      designation: "Zeyn Kermani​",
      companyName: "Manager Engineer Services​​",
    },
    {
      paragraph:
        "“The best thing about Goken is “it has ears”!  The management has an open-door policy and will do everything they can to help you achieve the career path you envision.”",
      designation: "​Aruna Kumara​",
      companyName: "Design Engineer III​​",
    },
    {
      paragraph:
        "“The organization is very entrusting of all their associates. Their actual self-managed project-based model has really helped me make my own schedule and allowed me to grow interpersonally.”",
      designation: "​Brandon Bordner​",
      companyName: "Technical Specialist​​",
    },
    {
      paragraph:
        "“I got into engineering to help people. The fact that the Goken mission statement focuses on the importance of not only the client but also the associates, appealed to me. The idea of being better together with the goal of helping others is something that resonates with me.”",
      designation: "Administrative Assistant​",
      companyName: "Goken Associate​",
    },
    {
      paragraph:
        "“What attracted me to Goken was the culture that fosters innovation and knowledge retention and the fact that good people are trained to be great engineers. I am extremely honored to be a part of Goken America.”",
      designation: "Design Engineer​",
      companyName: "Goken Associate​",
    },
    // {
    //   paragraph: `“I have worked with Goken through various automotive OEMs in the past and Goken's agility, quality, and ease to work with remain consistent throughout every experience. Their training program & engineering methods for new associates allows them to hit the ground running without burdening myself or my team. I am always at ease and confident in the results I will get when working with them.”`,
    //   designation: "Sr. Director, Electric Vehicle OEM",
    //   companyName: "Goken Associate",

    // },
    // {
    //   paragraph:
    //     "I have worked with Goken through various automotive OEMs in the past and Goken's agility, quality, and ease to work with remain consistent throughout every experience. Their training program & engineering methods for new associates allows them to hit the ground running without burdening myself or my team. I am always at ease and confident in the results I will get when working with them.",
    //   designation: "Sr. Director, Electric Vehicle OEM",
    //   companyName: "Goken Associate",

    // },
  ];
  async function addFadeEffect(event) {
    try {
      console.log(event);
      let child = event.target;
      const element = child.closest(".carousel-slider-wrapper");
      element.classList.add("testimonial-opacity");
      const otherElements = document.querySelectorAll(".active-opacity");
      console.log("Other Elements are");
      otherElements.forEach((element) => {
        element.classList.remove("active-opacity");
      });
      const targetElement = child.closest("li");
      console.log(targetElement);
      targetElement.classList.add("active-opacity");
      // const element = document.querySelectorAll(".carousel-slider-wrapper");
      console.log(element, "is the one");
    } catch (error) {}
  }

  const hoverHandler = (event) => {
    // const element = document.querySelectorAll(".testimonial-item");
    console.log(event);
    addFadeEffect(event);
    const catchEventDiv = event.target;
    const catchEvenList = event.target.classList.value;
    // event.target.classList.remove("testimonial-opacity");
  };

  const CustomButtonGroupAsArrows = ({ next, previous }) => {
    return (
      <div
        style={{
          textAlign: "center",
        }}
      >
        <button onClick={previous}>Prev</button>
        <button onClick={next}>Next</button>
      </div>
    );
  };

  const removeHoverHandler = (event) => {
    console.log(event, "remove hover handler");
    let child = event.target;
    const element = child.closest(".carousel-slider-wrapper");
    element.classList.remove("testimonial-opacity");
    const otherElements = document.querySelectorAll(".active-opacity");
    console.log("Other Elements are");
    otherElements.forEach((element) => {
      element.classList.remove("active-opacity");
    });
    // const element = document.querySelectorAll(".testimonial-item");
    // element.forEach((card) => {
    //   if (card.classList.contains("testimonial-opacity")) {
    //     card.classList.remove("testimonial-opacity");
    //   }
    // });
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 10000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1023, min: 768 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="section section-br">
      <div className="container">
        <div className="careers-wrapper">
          <div className="container">
            <div className="aboutus-testimonial-section">
              {/* <Fade bottom cascade> */}
              {hideTitle && (
                <h2 className="heading-margin heading heading-bottom-space">
                  Associate Testimonials
                </h2>
              )}
              {/* </Fade> */}
              <p className="para common-bottom-space">
                This is what our associates have to say about working at Goken
              </p>

              <div className="slider-wrapper carousel-slider-wrapper">
                <Carousel
                  arrows={false}
                  customButtonGroup={<CustomButtonGroupAsArrows />}
                  responsive={responsive}
                  autoPlay={true}
                  infinite={true}
                  autoPlaySpeed={4000}
                  onMouseEnter={() => setIsPlaying(false)}
                  onMouseLeave={() => setIsPlaying(true)}
                >
                  {testimonialSlides &&
                    testimonialSlides.map(
                      (
                        { paragraph, designation, companyName, logoImage },
                        index
                      ) => (
                        <div index={index}>
                          {" "}
                          <div
                            className="testimonial-item  box-shadow"
                            onMouseEnter={(event) => hoverHandler(event)}
                            onMouseLeave={(event) => removeHoverHandler(event)}
                          >
                            {logoImage ? (
                              <img
                                src={logoImage.url}
                                alt="Company Logo Image"
                              ></img>
                            ) : (
                              <img
                                className="slider-card-icon"
                                src={sliderCardIcon}
                                alt="Company Logo Image"
                              ></img>
                              // <div className={`testimonial-icon bg-en`}></div>
                            )}
                            <div className="para">
                              <p className="">{paragraph}</p>
                            </div>{" "}
                            <br></br>
                            <div className="name-wrapper semi-title">
                              <h4 className="no-margin"> {designation}</h4>

                              <h4 className="semi-title regular no-margin">
                                {companyName}
                              </h4>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                </Carousel>
              </div>
            </div>
          </div>
          <div className="careers-cta">
            <h2 className="heading">READY TO GET STARTED ?</h2>
            <div className=" careers-buttons m-top-20px">
              <WipeBtnBlack
                text="Explore Career Opportunities"
                url="/careers/"
                // notBtn={true}
              ></WipeBtnBlack>
              {/* <WipeBtnBlack
                text="Explore Life at Goken"
                url="/experiencelifeatgoken"
                // notBtn={true}
              ></WipeBtnBlack> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderCarousel;
